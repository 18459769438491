import React from 'react';

const SalesforceCommerceCloud = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.4487 20.3576C35.3056 20.3576 35.1684 20.2994 35.0673 20.1959C34.9661 20.0924 34.9093 19.9521 34.9093 19.8057V7.39951C34.9093 6.81404 34.682 6.25259 34.2773 5.8386C33.8727 5.42461 33.324 5.192 32.7517 5.192H14.4124C13.8402 5.192 13.2914 5.42461 12.8868 5.8386C12.4822 6.25259 12.2548 6.81404 12.2548 7.39951V9.60702C12.2548 9.75338 12.198 9.89371 12.0969 9.99721C11.9957 10.1007 11.8585 10.1589 11.7154 10.1589C11.5724 10.1589 11.4351 10.1007 11.334 9.99721C11.2328 9.89371 11.176 9.75338 11.176 9.60702V7.39951C11.176 6.49789 11.5261 5.63324 12.1492 4.9957C12.7723 4.35815 13.6175 4 14.4987 4H32.7949C33.6761 4 34.5213 4.35815 35.1444 4.9957C35.7675 5.63324 36.1175 6.49789 36.1175 7.39951V19.8057C36.12 19.8908 36.1032 19.9753 36.0684 20.0527C36.0336 20.13 35.9818 20.1981 35.917 20.2515C35.8522 20.305 35.7763 20.3423 35.695 20.3607C35.6138 20.3791 35.5295 20.378 35.4487 20.3576Z"
      fill="#FCFCFD"
    />
    <path
      d="M32.6653 43.9998H14.4123C13.5311 43.9998 12.6859 43.6416 12.0628 43.0041C11.4397 42.3665 11.0896 41.5017 11.0896 40.6001V25.8761C11.0896 25.7297 11.1465 25.5894 11.2476 25.4859C11.3488 25.3824 11.4859 25.3242 11.629 25.3242C11.772 25.3242 11.9093 25.3824 12.0104 25.4859C12.1116 25.5894 12.1684 25.7297 12.1684 25.8761V40.6885C12.1684 41.274 12.3957 41.8354 12.8004 42.2494C13.205 42.6634 13.7537 42.896 14.326 42.896H32.6222C33.1944 42.896 33.7432 42.6634 34.1478 42.2494C34.5524 41.8354 34.7798 41.274 34.7798 40.6885V35.7657C34.7798 35.6193 34.8366 35.479 34.9377 35.3755C35.0389 35.272 35.1761 35.2138 35.3191 35.2138C35.4622 35.2138 35.5994 35.272 35.7006 35.3755C35.8017 35.479 35.8585 35.6193 35.8585 35.7657V40.6885C35.8372 41.5523 35.4952 42.3753 34.9021 42.9903C34.309 43.6054 33.5092 43.9664 32.6653 43.9998Z"
      fill="#FCFCFD"
    />
    <path
      d="M35.384 10.2678H11.6507C11.5077 10.2678 11.3704 10.2096 11.2693 10.1061C11.1681 10.0026 11.1113 9.86231 11.1113 9.71594C11.1113 9.56957 11.1681 9.42924 11.2693 9.32575C11.3704 9.22225 11.5077 9.16406 11.6507 9.16406H35.384C35.5271 9.16406 35.6643 9.22225 35.7654 9.32575C35.8666 9.42924 35.9234 9.56957 35.9234 9.71594C35.9234 9.86231 35.8666 10.0026 35.7654 10.1061C35.6643 10.2096 35.5271 10.2678 35.384 10.2678Z"
      fill="#FCFCFD"
    />
    <path
      d="M35.4055 36.2288H11.6722C11.5291 36.2288 11.3919 36.1706 11.2908 36.0671C11.1896 35.9636 11.1328 35.8232 11.1328 35.6769C11.1328 35.5305 11.1896 35.3902 11.2908 35.2867C11.3919 35.1832 11.5291 35.125 11.6722 35.125H35.4055C35.5485 35.125 35.6857 35.1832 35.7869 35.2867C35.888 35.3902 35.9449 35.5305 35.9449 35.6769C35.9449 35.8232 35.888 35.9636 35.7869 36.0671C35.6857 36.1706 35.5485 36.2288 35.4055 36.2288Z"
      fill="#FCFCFD"
    />
    <path
      d="M25.9339 40.1819H21.6187C21.4757 40.1819 21.3385 40.1238 21.2374 40.0203C21.1362 39.9168 21.0793 39.7764 21.0793 39.63C21.0793 39.4836 21.1362 39.3433 21.2374 39.2398C21.3385 39.1363 21.4757 39.0781 21.6187 39.0781H25.9339C26.0769 39.0781 26.2142 39.1363 26.3153 39.2398C26.4165 39.3433 26.4733 39.4836 26.4733 39.63C26.4733 39.7764 26.4165 39.9168 26.3153 40.0203C26.2142 40.1238 26.0769 40.1819 25.9339 40.1819Z"
      fill="#FCFCFD"
    />
    <path
      d="M24.4236 7.55297H22.5897C22.4466 7.55297 22.3094 7.49479 22.2083 7.39129C22.1071 7.28779 22.0503 7.14746 22.0503 7.0011C22.0503 6.85473 22.1071 6.71426 22.2083 6.61077C22.3094 6.50727 22.4466 6.44922 22.5897 6.44922H24.4236C24.5667 6.44922 24.7038 6.50727 24.805 6.61077C24.9062 6.71426 24.963 6.85473 24.963 7.0011C24.963 7.14746 24.9062 7.28779 24.805 7.39129C24.7038 7.49479 24.5667 7.55297 24.4236 7.55297Z"
      fill="#FCFCFD"
    />
    <path
      d="M23.5175 31.9922C21.6262 31.9922 19.7774 31.418 18.2051 30.3425C16.6328 29.2669 15.4078 27.7383 14.685 25.9501C13.9622 24.1619 13.7742 22.1945 14.1447 20.2969C14.5153 18.3993 15.4277 16.6569 16.7666 15.2901C18.1055 13.9233 19.8106 12.9937 21.6661 12.6188C23.5216 12.244 25.4441 12.4408 27.1902 13.1843C28.9364 13.9279 30.4276 15.1848 31.4752 16.7959C32.5229 18.407 33.0798 20.2998 33.0756 22.2349C33.0698 24.8247 32.0603 27.3065 30.2684 29.1357C28.4766 30.9648 26.0487 31.9922 23.5175 31.9922ZM23.5175 13.5594C21.8405 13.5594 20.2011 14.0683 18.8067 15.0216C17.4123 15.9748 16.3255 17.3298 15.6837 18.915C15.0419 20.5003 14.874 22.2446 15.2012 23.9275C15.5284 25.6103 16.3359 27.1561 17.5218 28.3694C18.7076 29.5827 20.2185 30.409 21.8633 30.7437C23.5081 31.0785 25.213 30.9067 26.7624 30.25C28.3118 29.5934 29.636 28.4815 30.5678 27.0548C31.4995 25.6281 31.9968 23.9508 31.9968 22.2349C31.9968 19.934 31.1034 17.7274 29.5133 16.1004C27.9231 14.4734 25.7664 13.5594 23.5175 13.5594Z"
      fill="#FFF500"
    />
    <path
      d="M24.251 26.3396H22.7838C22.1773 26.3396 21.5956 26.093 21.1667 25.6542C20.7378 25.2153 20.4968 24.6202 20.4968 23.9996C20.5022 23.855 20.5607 23.7177 20.6607 23.6153C20.7607 23.513 20.8949 23.4532 21.0362 23.4477C21.1793 23.4477 21.3164 23.5058 21.4176 23.6093C21.5187 23.7128 21.5756 23.8532 21.5756 23.9996C21.5756 24.3275 21.7029 24.6418 21.9295 24.8736C22.156 25.1055 22.4634 25.2358 22.7838 25.2358H24.251C24.4217 25.2593 24.5954 25.2451 24.7603 25.1943C24.9253 25.1435 25.0777 25.0572 25.2074 24.9411C25.3371 24.8251 25.4411 24.6821 25.5123 24.5216C25.5835 24.3611 25.6203 24.1869 25.6203 24.0107C25.6203 23.8344 25.5835 23.6601 25.5123 23.4996C25.4411 23.3391 25.3371 23.1961 25.2074 23.08C25.0777 22.964 24.9253 22.8777 24.7603 22.8269C24.5954 22.7761 24.4217 22.7619 24.251 22.7854H22.7838C22.1773 22.7854 21.5956 22.5389 21.1667 22.1001C20.7378 21.6613 20.4968 21.066 20.4968 20.4454C20.4968 19.8248 20.7378 19.2297 21.1667 18.7909C21.5956 18.352 22.1773 18.1055 22.7838 18.1055H24.251C24.8558 18.1112 25.4342 18.3596 25.8619 18.7972C26.2896 19.2348 26.5324 19.8266 26.538 20.4454C26.538 20.5918 26.4812 20.7323 26.38 20.8357C26.2789 20.9392 26.1417 20.9973 25.9986 20.9973C25.8556 20.9973 25.7184 20.9392 25.6172 20.8357C25.5161 20.7323 25.4592 20.5918 25.4592 20.4454C25.4592 20.1176 25.332 19.8032 25.1054 19.5714C24.8788 19.3396 24.5714 19.2092 24.251 19.2092H22.7838C22.4634 19.2092 22.156 19.3396 21.9295 19.5714C21.7029 19.8032 21.5756 20.1176 21.5756 20.4454C21.5756 20.7733 21.7029 21.0878 21.9295 21.3196C22.156 21.5514 22.4634 21.6816 22.7838 21.6816H24.251C24.5685 21.6499 24.889 21.6866 25.1919 21.7893C25.4948 21.8919 25.7733 22.0582 26.0096 22.2776C26.2459 22.4969 26.4347 22.7645 26.5639 23.0629C26.6932 23.3614 26.7599 23.6842 26.7599 24.0107C26.7599 24.3371 26.6932 24.6598 26.5639 24.9582C26.4347 25.2567 26.2459 25.5243 26.0096 25.7436C25.7733 25.963 25.4948 26.1293 25.1919 26.2319C24.889 26.3345 24.5685 26.3712 24.251 26.3396Z"
      fill="#FFF500"
    />
    <path
      d="M23.5174 19.2555C23.3744 19.2555 23.2371 19.1973 23.136 19.0938C23.0348 18.9903 22.978 18.85 22.978 18.7036V17.048C22.978 16.9016 23.0348 16.7613 23.136 16.6578C23.2371 16.5543 23.3744 16.4961 23.5174 16.4961C23.6605 16.4961 23.7977 16.5543 23.8989 16.6578C24 16.7613 24.0568 16.9016 24.0568 17.048V18.7036C24.0568 18.85 24 18.9903 23.8989 19.0938C23.7977 19.1973 23.6605 19.2555 23.5174 19.2555Z"
      fill="#FFF500"
    />
    <path
      d="M23.5174 28.0836C23.3744 28.0836 23.2371 28.0254 23.136 27.9219C23.0348 27.8184 22.978 27.6781 22.978 27.5317V25.8761C22.978 25.7297 23.0348 25.5894 23.136 25.4859C23.2371 25.3824 23.3744 25.3242 23.5174 25.3242C23.6605 25.3242 23.7977 25.3824 23.8989 25.4859C24 25.5894 24.0568 25.7297 24.0568 25.8761V27.5317C24.0568 27.6781 24 27.8184 23.8989 27.9219C23.7977 28.0254 23.6605 28.0836 23.5174 28.0836Z"
      fill="#FFF500"
    />
    <path
      d="M10.3347 24.0453C10.2425 24.0678 10.1465 24.0678 10.0543 24.0453L0.258898 17.8201C0.183485 17.7688 0.120797 17.7003 0.0758521 17.6198C0.0309074 17.5393 0.00495522 17.4492 0 17.3566C0.00157809 17.2603 0.0259535 17.1658 0.0710455 17.0812C0.116138 16.9967 0.180591 16.9244 0.258898 16.8709L10.0543 10.7341C10.1363 10.6857 10.2293 10.6602 10.324 10.6602C10.4187 10.6602 10.5117 10.6857 10.5937 10.7341C10.6773 10.7835 10.7471 10.8541 10.7962 10.9392C10.8454 11.0243 10.8722 11.1209 10.8741 11.2197V14.0233H15.3188C15.4618 14.0233 15.599 14.0815 15.7001 14.185C15.8013 14.2885 15.8582 14.4288 15.8582 14.5752C15.8582 14.7215 15.8013 14.8619 15.7001 14.9654C15.599 15.0689 15.4618 15.127 15.3188 15.127H10.3347C10.1917 15.127 10.0545 15.0689 9.95335 14.9654C9.8522 14.8619 9.79533 14.7215 9.79533 14.5752V12.2131L1.57498 17.3787L9.79533 22.5884V20.1601C9.79533 20.0137 9.8522 19.8734 9.95335 19.7699C10.0545 19.6664 10.1917 19.6082 10.3347 19.6082H12.8375C12.9806 19.6082 13.1178 19.6664 13.2189 19.7699C13.3201 19.8734 13.3769 20.0137 13.3769 20.1601C13.3769 20.3065 13.3201 20.4469 13.2189 20.5504C13.1178 20.6539 12.9806 20.712 12.8375 20.712H10.8741V23.5818C10.8744 23.6811 10.8485 23.7786 10.7991 23.8641C10.7498 23.9496 10.6788 24.0198 10.5937 24.0674L10.3347 24.0453Z"
      fill="#FCFCFD"
    />
    <path
      d="M36.7 34.2223H36.4412C36.3575 34.1729 36.2878 34.1022 36.2386 34.0171C36.1895 33.932 36.1626 33.8354 36.1607 33.7366V30.933H31.6729C31.5299 30.933 31.3927 30.875 31.2915 30.7715C31.1903 30.668 31.1335 30.5275 31.1335 30.3812C31.1335 30.2348 31.1903 30.0945 31.2915 29.991C31.3927 29.8875 31.5299 29.8293 31.6729 29.8293H36.6569C36.8 29.8293 36.9372 29.8875 37.0384 29.991C37.1395 30.0945 37.1963 30.2348 37.1963 30.3812V32.7432L45.4167 27.5777L37.1963 22.2796V24.7741C37.1963 24.9205 37.1395 25.0608 37.0384 25.1643C36.9372 25.2678 36.8 25.326 36.6569 25.326H34.1541C34.0111 25.326 33.8739 25.2678 33.7728 25.1643C33.6716 25.0608 33.6147 24.9205 33.6147 24.7741C33.6147 24.6278 33.6716 24.4874 33.7728 24.3839C33.8739 24.2804 34.0111 24.2223 34.1541 24.2223H36.1175V21.2862C36.1189 21.1889 36.1453 21.0938 36.1942 21.0103C36.2431 20.9268 36.3127 20.8581 36.396 20.811C36.4792 20.764 36.5732 20.7403 36.6682 20.7423C36.7633 20.7444 36.8562 20.7721 36.9374 20.8227L46.7328 27.1141C46.8137 27.1599 46.8812 27.2271 46.9282 27.3086C46.9751 27.3902 46.9999 27.4831 46.9999 27.5777C46.9999 27.6723 46.9751 27.7653 46.9282 27.8468C46.8812 27.9283 46.8137 27.9954 46.7328 28.0412L36.9374 34.2002C36.8605 34.2236 36.7798 34.2312 36.7 34.2223Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default SalesforceCommerceCloud;
